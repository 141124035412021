export default {
  reportForm039: state => state.reportForm039,
  reportForm039_3: state => state.reportForm039_3,
  reportForm037: state => state.reportForm037,
  outpatientSurgeries: state => state.outpatientSurgeries,
  paidServicesJournal: state => state.paidServicesJournal,
  paidServicesJournalCount: state => state.paidServicesJournalCount,
  paidServicesJournalTotal: state => state.paidServicesJournalTotal,
  certificatesWorkPerformed: state => state.certificatesWorkPerformed,
  certificatesWorkPerformedCount: state => state.certificatesWorkPerformedCount,
  certificatesWorkPerformedTotal: state => state.certificatesWorkPerformedTotal,
  paidMedicalServicesInformation: state => state.paidMedicalServicesInformation,
}
