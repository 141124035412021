import axios from '@/libs/axios'
import moment from 'moment/moment'

export default {
  // Nds
  getNds({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'nds-type/', method: 'GET'
      })
        .then(res => {
          commit('SET_NDS_TYPE', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addNds({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('nds-type/', item)
        .then(res => {
          commit('ADD_NDS_TYPE', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editNds({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`nds-type/${item.id}/`, {
        value: item.value,
      })
        .then(res => {
          commit('EDIT_NDS_TYPE', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Units
  getUnits({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'units-type/', method: 'GET'
      })
        .then(res => {
          commit('SET_UNITS_TYPE', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addUnit({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('units-type/', item)
        .then(res => {
          commit('ADD_UNIT_TYPE', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editUnit({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`units-type/${item.id}/`, {
        short_name: item.short_name,
        full_name: item.full_name
      })
        .then(res => {
          commit('EDIT_UNIT_TYPE', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  removeUnit({commit}, cardId) {
    return new Promise((resolve, reject) => {
      axios.delete(`units-type/${cardId}/`)
        .then(res => {
          commit('REMOVE_UNIT_TYPE', cardId)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Discount Cards
  getDiscountsCards({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'discount-card/', method: 'GET'
      })
        .then(res => {
          commit('SET_DISCOUNTS_CARDS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addDiscountCard({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('discount-card/', item)
        .then(res => {
          commit('ADD_DISCOUNT_CARD', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editDiscountCard({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`discount-card/${item.id}/`, {name: item.name, percent: item.percent})
        .then(res => {
          commit('EDIT_DISCOUNTS_CARDS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  removeDiscountCard({commit}, cardId) {
    return new Promise((resolve, reject) => {
      axios.delete(`discount-card/${cardId}/`)
        .then(res => {
          commit('REMOVE_DISCOUNT_CARD', cardId)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  // Payment Method
  getPaymentMethods({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'payment-method/', method: 'GET'
      })
        .then(res => {
          commit('SET_PAYMENT_METHODS', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addPaymentsMethod({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('payment-method/', item)
        .then(res => {
          commit('ADD_PAYMENT_METHOD', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editPaymentsMethod({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`payment-method/${item.id}/`, item)
        .then(res => {
          commit('EDIT_PAYMENT_METHOD', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  removePaymentsMethod({commit}, paymentId) {
    return new Promise((resolve, reject) => {
      axios.delete(`payment-method/${paymentId}/`)
        .then(res => {
          commit('REMOVE_PAYMENT_METHOD', paymentId)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getPaymentMethodExtendedCategories({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `payment-method-extended-category/`, method: 'GET'
      })
        .then(res => {
          commit('SET_PAYMENT_METHOD_EXTENDED_CATEGORIES', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Payment Direction
  getPaymentDirections({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'payment-direction/', method: 'GET'
      })
        .then(res => {
          commit('SET_PAYMENT_DIRECTION', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  getPaymentDirectionTypes({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'payment-direction-type/', method: 'GET'
      })
        .then(res => {
          commit('SET_PAYMENT_DIRECTION_TYPES', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  removePaymentDirection({commit}, paymentDirectionId) {
    return new Promise((resolve, reject) => {
      axios.delete(`payment-direction/${paymentDirectionId}/`)
        .then(res => {
          commit('REMOVE_PAYMENT_DIRECTION', paymentDirectionId)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addPaymentDirection({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('payment-direction/', item)
        .then(res => {
          commit('ADD_PAYMENT_DIRECTION', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editPaymentDirection({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`payment-direction/${item.id}/`, item)
        .then(res => {
          commit('EDIT_PAYMENT_DIRECTION', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Contacts
  getContacts({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'contacts/', method: 'GET'
      })
        .then(res => {
          commit('SET_CONTACTS', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addContact({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('contacts/', item)
        .then(res => {
          commit('ADD_CONTACT', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editContact({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`contacts/${item.id}/`, {name: item.name})
        .then(res => {
          commit('EDIT_CONTACT', res.data)
          resolve(res)
        })
    })
  },
  removeContact({commit}, contactId) {
    return new Promise((resolve, reject) => {
      axios.delete(`contacts/${contactId}/`)
        .then(res => {
          commit('REMOVE_CONTACT', contactId)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Employee
  getEmployeeByClinicPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'users-by-clinic/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_EMPLOYEE_BY_CLINIC_PER_PAGE', res.data.results)
          commit('SET_EMPLOYEE_BY_CLINIC_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getEmployee({commit}, params = {}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'users/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_EMPLOYEE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addEmployee({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `users/`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: item
      })
        .then(res => {
          commit('ADD_EMPLOYEE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editEmployee({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `users/${item.get('id')}/`,
        method: 'PATCH',
        headers: {'Content-Type': 'multipart/form-data'},
        data: item
      })
        .then(res => {
          commit('EDIT_EMPLOYEE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  removeEmployee({commit}, contactId) {
    return new Promise((resolve, reject) => {
      axios.delete(`users/${contactId}/`)
        .then(res => {
          commit('REMOVE_EMPLOYEE', contactId)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Roles
  getRoles({commit}, params = {}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'users-group/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ROLES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getRolesByIntegratorId({commit}, params) {
    return new Promise((resolve, reject) => {
      const queryParams = {integrator_id: params.integrator_id}
      if (params.all_users) {
        queryParams.all_users = true
      }
      if (params.category) {
        queryParams.category = params.category
      }
      axios({
        url: `users-group/`,
        method: 'GET',
        params: queryParams
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  deleteRole({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'users-group/' + id + '/', method: 'DELETE'
      })
        .then(res => {
          commit('REMOVE_ROLE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  saveRole({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'users-group/', data: data, method: 'POST'
      })
        .then(res => {
          commit('ADD_ROLE', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editRole({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `users-group/${data.id}/`, data: data, method: 'PATCH'
      })
        .then(res => {
          commit('EDIT_ROLE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  copyRole({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'copy-users-group/', data: data, method: 'POST'
      })
        .then(res => {
          commit('ADD_ROLE', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Permissions
  getPermissions({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'permissions/', method: 'GET'
      })
        .then(res => {
          commit('SET_PERMISSIONS', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getPermissionsForUser({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'users/permissions/' + id, method: 'GET'
      })
        .then(res => {
          commit('SET_PERMISSIONS_FOR_USER', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getBites({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'bites/', data: item, method: 'GET'
      })
        .then(res => {
          commit('SET_BITES', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Materials
  getLotWithMaterial({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'lot-with-material/', method: 'GET', params: params
      })
        .then(res => {
          commit('SET_LOT_WITH_MATERIAL_ITEMS', res.data.results)
          commit('SET_LOT_WITH_MATERIAL_ITEMS_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getMaterialGroups({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'materials-group/', method: 'GET'
      })
        .then(res => {
          commit('SET_MATERIAL_GROUPS', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addMaterialsGroup({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'materials-group/', method: 'POST', data: payload
      })
        .then(res => {
          commit('material_group_added', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editMaterialsGroup({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `materials-group/${payload.id}/`, method: 'PATCH', data: {name: payload.name}
      })
        .then(res => {
          commit('material_group_edited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deleteMaterialGroup({commit}, group) {
    return new Promise((resolve, reject) => {
      axios.delete(`materials-group/${group.id}/`)
        .then(res => {
          commit('MATERIAL_GROUP_DELETED', group)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deleteMaterial({commit}, material) {
    return new Promise((resolve, reject) => {
      axios({
        url: `materials/${material.id}/`, method: 'DELETE'
      })
        .then(res => {
          commit('MATERIAL_DELETED', material)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getMaterialsByGroup({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `materials-list-group/${params.group.id}`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => {
          commit('SET_MATERIALS_BY_GROUP', {list: res.data, groupId: params.group.id})
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addMaterial({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({url: 'materials/', method: 'POST', data: payload})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  editMaterial({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({url: `materials/${payload.id}/`, method: 'PATCH', data: payload})
        .then(res => {
          commit('material_edited', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  addMaterialLot({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: 'material-lot/', method: 'POST', data: item})
        .then(res => {
          commit('MATERIAL_LOT_ADDED', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editMaterialLot({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({url: `material-lot/${item.id}/`, method: 'PATCH', data: item})
        .then(res => {
          commit('MATERIAL_LOT_EDITED', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  fetchMaterialLots({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'material-lot/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_MATERIAL_LOTS', res.data.results)
          commit('SET_MATERIAL_LOTS_COUNT', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deleteMaterialLot({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.delete(`material-lot/${item.id}/`)
        .then(res => {
          commit('MATERIAL_LOT_DELETED', item)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  materialLotCancelPlacement({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `material-lot/${item.id}/cancel-placement`,
        method: 'PATCH',
      })
        .then(res => {
          commit('MATERIAL_LOT_CANCEL_PLACEMENT', item)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  materialLotPlace({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `material-lot/${item.id}/place`,
        method: 'PATCH',
      })
        .then(res => {
          commit('MATERIAL_LOT_PLACE', item)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  copyMaterialLot({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'copy-material-lot/',
        data: data,
        method: 'POST'
      })
        .then(res => {
          commit('MATERIAL_LOT_ADDED', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Diagnosis
  getDiagnosisSubGroups({commit}, groupId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `diagnosis-subgroup/${groupId}`, method: 'GET'
      })
        .then(res => {
          commit('SET_DIAGNOSIS_SUB_GROUPS', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addDiagnosis({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`diagnosis/`, payload)
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editDiagnosis({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`diagnosis/${payload.id}/`, payload)
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deleteDiagnosis({commit}, diagnosisId) {
    return new Promise((resolve, reject) => {
      axios.delete(`diagnosis/${diagnosisId}`)
        .then(res => {
          commit('DIAGNOSIS_DELETE', diagnosisId)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getDiagnosisGroupsMain({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'diagnosis-group/', method: 'GET'
      })
        .then(res => {
          commit('SET_DIAGNOSIS_GROUPS_MAIN', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getDiagnosisGroupsAll({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'group-diagnosis/', method: 'GET'
      })
        .then(res => {
          commit('SET_DIAGNOSIS_GROUPS_ALL', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addDiagnosisGroup({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('group-diagnosis/', payload)
        .then(res => {
          commit('diagnosis_group_added', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editDiagnosisGroup({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`group-diagnosis/${payload.id}/`, payload)
        .then(res => {
          commit('diagnosis_group_edited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deleteDiagnosisGroup({commit}, group) {
    return new Promise((resolve, reject) => {
      axios.delete(`group-diagnosis/${group.id}/`)
        .then(res => {
          commit('DIAGNOSIS_GROUP_DELETED', group)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getDiagnosis({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `diagnosis-group/${params.group.id}`, method: 'GET',
        params: params.hasOwnProperty('search') ? {search: params.search} : {}
      })
        .then(res => {
          commit('SET_DIAGNOSIS', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getTemplateWithParams({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-card-template/`, method: 'GET', params: params
      })
        .then(res => {
          commit('SET_PATIENT_TEMPLATES_SEARCH', res.data.results)
          commit('SET_PATIENT_TEMPLATES_SEARCH_TOTAL_COUNT', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getPatientCardTemplates({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-card-template/`, method: 'GET', params: params
      })
        .then(res => {resolve(res)})
        .catch(error => {reject({message: error})})
    })
  },
  getAllPatientCardTemplatesByMainGroup({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-card-template-all-sub-group/`, method: 'GET', params: params
      })
        .then(res => {resolve(res)})
        .catch(error => {reject({message: error})})
    })
  },
  getAllDiagnosisWithParams({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `diagnosis/`, method: 'GET', params: params
      })
        .then(res => {
          commit('SET_DIAGNOSIS', res.data.results)
          commit('set_diagnosis_total_count', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getAllDiagnosisWithoutParams({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `diagnosis/`,
        method: 'GET'
      })
        .then(res => {
          commit('SET_DIAGNOSIS', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getPatientCardTemplateMainGroups({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-template-main-group/`, method: 'GET'
      })
        .then(res => {
          commit('SET_PATIENT_TEMPLATES_GROUPS_MAIN', res.data.filter((item) => {
            return item.parent_group_id === null
          }))
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getPatientCardTemplateGroups({commit}, groupId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-template-sub-group/${groupId}`, method: 'GET'
      })
        .then(res => {
          commit('SET_PATIENT_TEMPLATES_GROUPS', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addTemplatePatient({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `add-patient-template/`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: data
      })
        .then(res => {
          commit('ADD_PATIENT_TEMPLATE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  copyTemplatePatient({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'copy-patient-template/', data: data, method: 'POST'
      })
        .then(res => {
          commit('ADD_PATIENT_TEMPLATE', res.data)
          resolve(res)
        }).catch(error => reject({message: error}))
    })
  },
  editTemplatePatient({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `add-patient-template/${data.get('id')}/`,
        method: 'PATCH',
        headers: {'Content-Type': 'multipart/form-data'},
        data: data
      })
        .then(res => {
          commit('edit_patient_template', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteTemplatePatient({commit}, templatePatientId) {
    return new Promise((resolve, reject) => {
      axios.delete(`add-patient-template/${templatePatientId}`)
        .then(res => {
          commit('TEMPLATE_PATIENT_DELETE', templatePatientId)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Dental Services
  getServicesGroupsMain({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `services-group/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_SERVICES_GROUPS_MAIN', {category: params.category, groups: res.data})
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getServices({commit}, params) {
    const queryParams = {category: params.category}
    if (params.hasOwnProperty('search')) {
      queryParams.search = params.search
    }
    return new Promise((resolve, reject) => {
      axios({url: `services/group/${params.group.id}`, method: 'GET', params: queryParams})
        .then(res => {
          commit('SET_SERVICES', {category: params.category, services: res.data, id: params.group.id})
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getDentalServicesWithTime({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `services-with-time/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DENTAL_SERVICES_WITH_TIME', res.data.results)
          commit('SET_DENTAL_SERVICES_WITH_TIME_TOTAL_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getServiceTypes({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `service-type/`,
        method: 'GET',
      })
        .then(res => {
          commit('SET_SERVICE_TYPES', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getAllServicesWithPagination({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `services/`, method: 'GET', params: params})
        .then(res => {
          commit('SET_SERVICES', {services: res.data.results})
          commit('SET_SERVICES_TOTAL_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getServicesGroups({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `services-subgroup/${params.groupId}`, method: 'GET', params: {category: params.category}})
        .then(res => {
          commit('SET_SERVICES_GROUPS', {category: params.category, groups: res.data, id: params.groupId})
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getAllServicesGroups({commit}, params = {}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `services-subgroup/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ALL_SERVICES_GROUPS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addServiceGroup({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('services-group/', payload)
        .then(res => {
          commit('SERVICE_GROUP_ADDED', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editServiceGroup({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`services-group/${payload.id}/`, payload)
        .then(res => {
          commit('service_group_edited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  getService({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`services/${id}/`)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  addService({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`services/`, payload)
        .then(res => {
          commit('SERVICE_ADDED', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editService({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`services/${payload.id}/`, payload)
        .then(res => {
          commit('service_edited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deleteDentalService({commit}, dentalServiceId) {
    return new Promise((resolve, reject) => {
      axios.delete(`services/${dentalServiceId}/`)
        .then(res => {
          commit('DENTAL_SERVICE_DELETE', dentalServiceId)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  copyDentalService({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'copy-service/',
        data: data,
        method: 'POST'
      })
        .then(res => {
          commit('SERVICE_ADDED', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  changeServiceArchivedAttr({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({url: `service/${data.id}/archived`, method: 'PATCH', data: data})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  deleteDentalServiceGroup({commit}, group) {
    return new Promise((resolve, reject) => {
      axios.delete(`services-group/${group.id}/`)
        .then(res => {
          commit('SERVICE_GROUP_DELETED', group)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  changeServiceGroupDeactivatedAttr({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `service-group/${data.id}/deactivated`,
        method: 'PUT',
        data: data
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  getServicesTimeLimits({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `services/group/${params.group_id}/time-limit`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getServicesSpecialistTypesWage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `services/group/${params.group_id}/specialist-type-wage`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getCalculatedServiceTariff({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `services/group/${params.group_id}/calculated-service-tariff`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Plans
  getPlans({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `plans/`, method: 'GET', params: params})
        .then(res => {
          commit('SET_PLANS', res.data.results)
          commit('SET_PLANS_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getPlansWithTime({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `plans-with-time/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PLANS_WITH_TIME', res.data.results)
          commit('SET_PLANS_WITH_TIME_TOTAL_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addPlan({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('plans/', payload)
        .then(res => {
          commit('plan_added', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  copyPlan({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'copy-plan/',
        method: 'POST',
        data: data
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editPlan({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`plans/${payload.id}/`, payload)
        .then(res => {
          commit('plan_edited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addBite({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('bites/', payload)
        .then(res => {
          commit('bite_added', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editBite({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.patch(`bites/${payload.id}/`, {name: payload.name})
        .then(res => {
          commit('bite_edited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  removeBite({commit}, biteId) {
    return new Promise((resolve, reject) => {
      axios.delete(`bites/${biteId}/`)
        .then(res => {
          commit('bite_removed', biteId)
          resolve(res)
        })
    })
  },
  removePlan({commit}, planId) {
    return new Promise((resolve, reject) => {
      axios.delete(`plans/${planId}/`)
        .then(res => {
          commit('PLAN_REMOVED', planId)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  fetchIntegrators({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('setIntegrators', res.data.results)
          commit('setIntegratorsCount', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  fetchIntegratorsForDropdown({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `integrator-for-dropdown/`, method: 'GET', params: params})
        .then(res => {
          commit('setIntegrators', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  fetchServiceTime({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `services-time/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('setServiceTimes', res.data.results)
          commit('setServiceTimeCount', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchPatientType({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-type/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('setPatientTypes', res.data.results)
          commit('setPatientTypesCount', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  removeIntegrator({commit}, integrator) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${integrator.id}/`, method: 'DELETE'
      })
        .then(res => {
          commit('integratorDeleted', integrator.id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editIntegrator({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${data.get('id')}/`,
        method: 'PATCH',
        headers: {'Content-Type': 'multipart/form-data'},
        data: data
      })
        .then(res => {
          commit('INTEGRATOR_EDITED', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addIntegrator({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({url: `integrator/`, method: 'POST', headers: {'Content-Type': 'multipart/form-data'}, data})
        .then(res => {
          commit('INTEGRATOR_ADDED', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getIntegrator({commit}, integratorId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${integratorId}/`, method: 'GET'
      })
        .then(res => {
          commit('SET_INTEGRATOR', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getDentalLaboratories({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory-for-dropdown/`,
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getIntegratorDentalLaboratories({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${params.id}/dental-laboratory`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getDentalLaboratoryIntegrators({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-laboratory/${params.id}/integrator`,
        method: 'GET',
        params: params.queryParams
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Integrator sms-notification balance
  plusIntegratorSmsNotificationBalance({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `integrator/${params.id}/sms-notification-balance/plus`, method: 'PATCH', data: params.bodyParams})
        .then(res => {
          commit(
            'PLUS_INTEGRATOR_SMS_NOTIFICATION_BALANCE',
            {id: params.id, sms_notification_balance: res.data.sms_notification_balance}
          )
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  saveIntegratorSmsNotificationSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `integrator/${params.id}/sms-notification-settings`, method: 'PATCH', data: params.data})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  onViberChat({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${id}/viber-chat-on`,
        method: 'PATCH',
      })
        .then(res => {
          commit('VIBER_CHAT_ENABLE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  offViberChat({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${id}/viber-chat-off`,
        method: 'PATCH',
      })
        .then(res => {
          commit('VIBER_CHAT_DISABLE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  onTelegramChat({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${id}/telegram-chat-on`,
        method: 'PATCH',
      })
        .then(res => {
          commit('TELEGRAM_CHAT_ENABLE', id)
          resolve(res)
        })
        .catch(error => {
          commit('TELEGRAM_CHAT_DISABLE', id)
          reject({message: error})
        })
    })
  },
  offTelegramChat({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${id}/telegram-chat-off`,
        method: 'PATCH',
      })
        .then(res => {
          commit('TELEGRAM_CHAT_DISABLE', id)
          resolve(res)
        })
        .catch(error => {
          commit('TELEGRAM_CHAT_ENABLE', id)
          reject({message: error})
        })
    })
  },
  editServiceTime({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `services-time/${data.id}/`, method: 'PATCH',
        data: data
      })
        .then(res => {
          commit('serviceTimeEdited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editPatientType({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-type/${data.id}/`, method: 'PATCH',
        data: data
      })
        .then(res => {
          commit('patientTypeEdited', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deletePatientType({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-type/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('PATIENT_TYPE_DELETE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addServiceTime({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `services-time/`, method: 'POST',
        data: data
      })
        .then(res => {
          commit('serviceTimeAdded', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addPatientType({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-type/`, method: 'POST',
        data: data
      })
        .then(res => {
          commit('patientTypeAdded', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  requestAction({commit}, config) {
    return new Promise((resolve, reject) => {
      axios(config.request)
        .then(res => {
          if (config.hasOwnProperty('successCallback') && config.request.method !== 'DELETE') {
            config.successCallback(commit, res)
          }
          if (config.hasOwnProperty('successCallback') && config.request.method === 'DELETE') {
            config.successCallback(commit, config.request.data)
          }
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Advisory Opinion
  getAdvisoryOpinion({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'advisory-opinion/', method: 'GET'
      })
        .then(res => {
          commit('SET_ADVISORY_OPINION', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addAdvisoryOpinion({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('advisory-opinion/', item)
        .then(res => {
          commit('ADD_ADVISORY_OPINION', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editAdvisoryOpinion({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`advisory-opinion/${item.id}/`, item)
        .then(res => {
          commit('EDIT_ADVISORY_OPINION', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deleteAdvisoryOpinion({commit}, advisoryOpinionId) {
    return new Promise((resolve, reject) => {
      axios.delete(`advisory-opinion/${advisoryOpinionId}/`)
        .then(res => {
          commit('ADVISORY_OPINION_DELETE', advisoryOpinionId)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchAdvisoryOpinion({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `advisory-opinion/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_ADVISORY_OPINION', res.data.results)
          commit('SET_ADVISORY_OPINION_COUNT', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // INSURANCE COMPANIES
  getInsuranceCompanies({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `insurance-company/`,
        method: 'GET'
      })
        .then(res => {
          commit('SET_INSURANCE_COMPANIES', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchInsuranceCompanies({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `insurance-company/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_INSURANCE_COMPANIES', res.data.results)
          commit('SET_INSURANCE_COMPANIES_COUNT', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addInsuranceCompany({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('insurance-company/', item)
        .then(res => {
          commit('INSURANCE_COMPANY_ADDED', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editInsuranceCompany({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`insurance-company/${item.id}/`, item)
        .then(res => {
          commit('INSURANCE_COMPANY_EDITED', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deleteInsuranceCompany({commit}, insuranceCompanyId) {
    return new Promise((resolve, reject) => {
      axios.delete(`insurance-company/${insuranceCompanyId}/`)
        .then(res => {
          commit('INSURANCE_COMPANY_DELETE', insuranceCompanyId)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Notification condition category
  fetchNotificationConditionCategoryPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary-notification-condition-category/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_NOTIFICATION_CONDITION_CATEGORIES_PER_PAGE', res.data.results)
          commit('SET_NOTIFICATION_CONDITION_CATEGORIES_COUNT', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchNotificationConditionCategory({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary-notification-condition-category/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_NOTIFICATION_CONDITION_CATEGORIES', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addNotificationConditionCategory({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dictionary-notification-condition-category/', item)
        .then(res => {
          commit('NOTIFICATION_CONDITION_CATEGORY_ADD', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editNotificationConditionCategory({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dictionary-notification-condition-category/${item.id}/`, item)
        .then(res => {
          commit('NOTIFICATION_CONDITION_CATEGORY_EDIT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deleteNotificationConditionCategory({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`dictionary-notification-condition-category/${id}/`)
        .then(res => {
          commit('NOTIFICATION_CONDITION_CATEGORY_DELETE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Legal persons
  fetchLegalPersonsPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `legal-person/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_LEGAL_PERSONS', res.data.results)
          commit('SET_LEGAL_PERSONS_COUNT', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchLegalPersons({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `legal-person/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_LEGAL_PERSONS', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addLegalPerson({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('legal-person/', item)
        .then(res => {
          commit('LEGAL_PERSON_ADD', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editLegalPerson({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`legal-person/${item.id}/`, item)
        .then(res => {
          commit('LEGAL_PERSON_EDIT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deleteLegalPerson({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`legal-person/${id}/`)
        .then(res => {
          commit('LEGAL_PERSON_DELETE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Third party organization
  fetchThirdPartyOrganizationsPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `third-party-organization/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_THIRD_PARTY_ORGANIZATIONS', res.data.results)
          commit('SET_THIRD_PARTY_ORGANIZATIONS_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  fetchThirdPartyOrganizationsForDropdown({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `third-party-organization-for-dropdown/`,
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  addThirdPartyOrganization({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('third-party-organization/', item)
        .then(res => {
          commit('THIRD_PARTY_ORGANIZATION_ADD', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editThirdPartyOrganization({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`third-party-organization/${item.id}/`, item)
        .then(res => {
          commit('THIRD_PARTY_ORGANIZATION_EDIT', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteThirdPartyOrganization({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`third-party-organization/${id}/`)
        .then(res => {
          commit('THIRD_PARTY_ORGANIZATION_DELETE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Contacts from
  fetchContactsFrom({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `contacts-from/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_CONTACTS_FROM', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Patient appeal result
  fetchPatientAppealResultsPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-appeal-result/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PATIENT_APPEAL_RESULTS_PER_PAGE', res.data.results)
          commit('SET_PATIENT_APPEAL_RESULT_COUNT', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchPatientAppealResults({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-appeal-result/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PATIENT_APPEAL_RESULTS', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addPatientAppealResult({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('patient-appeal-result/', item)
        .then(res => {
          commit('PATIENT_APPEAL_RESULT_ADD', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  editPatientAppealResult({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`patient-appeal-result/${item.id}/`, item)
        .then(res => {
          commit('PATIENT_APPEAL_RESULT_EDIT', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  deletePatientAppealResult({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`patient-appeal-result/${id}/`)
        .then(res => {
          commit('PATIENT_APPEAL_RESULT_DELETE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Physician protocol field
  fetchPhysicianProtocolFields({commit}, params) {
    return new Promise((resolve, reject) => {
      axios.get(`physician-protocol-field/`, params)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_FIELDS_SET', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addPhysicianProtocolField({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('physician-protocol-field/', payload)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_FIELD_ADDED', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editPhysicianProtocolField({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`physician-protocol-field/${item.id}/`, item)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_FIELD_EDIT', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deletePhysicianProtocolField({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`physician-protocol-field/${id}/`)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_FIELD_DELETE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Physician protocol field values
  fetchPhysicianProtocolFieldValues({commit}, params) {
    return new Promise((resolve, reject) => {
      axios.get(`physician-protocol-field/${params.id}/values`, params.queryParams)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_FIELD_VALUES_SET', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchPhysicianProtocolFieldValuesByParams({commit}, params) {
    return new Promise((resolve, reject) => {
      axios.get('physician-protocol-field-value/', params)
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addPhysicianProtocolFieldValue({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('physician-protocol-field-value/', payload)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_FIELD_VALUE_ADDED', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editPhysicianProtocolFieldValue({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`physician-protocol-field-value/${item.id}/`, item)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_FIELD_VALUE_EDIT', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deletePhysicianProtocolFieldValue({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`physician-protocol-field-value/${id}/`)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_FIELD_VALUE_DELETE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Physician protocol section
  fetchPhysicianProtocolSections({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'physician-protocol-section/', method: 'GET'
      })
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_SECTIONS_SET', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addPhysicianProtocolSection({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('physician-protocol-section/', payload)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_SECTION_ADDED', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editPhysicianProtocolSection({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`physician-protocol-section/${item.id}/`, item)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_SECTION_EDIT', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deletePhysicianProtocolSection({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`physician-protocol-section/${id}/`)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_SECTION_DELETE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Physician protocol subsection
  fetchPhysicianProtocolSectionWithSubsection({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'physician-protocol-section-with-subsection/', method: 'GET'
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchFreePhysicianProtocolSectionWithSubsection({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'free-physician-protocol-section-with-subsection/', method: 'GET'
      })
        .then(res => {
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  fetchPhysicianProtocolSubsection({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `physician-protocol-section/${params.id}/subsection`,
        method: 'GET'
      })
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_SUBSECTIONS_SET', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  addPhysicianProtocolSubsection({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('physician-protocol-subsection/', payload)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_SUBSECTION_ADDED', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  editPhysicianProtocolSubsection({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`physician-protocol-subsection/${item.id}/`, item)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_SUBSECTION_EDIT', res.data)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  deletePhysicianProtocolSubsection({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`physician-protocol-subsection/${id}/`)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_SUBSECTION_DELETE', id)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Physician protocol
  fetchPhysicianProtocolsPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `physician-protocol/`, method: 'GET', params: params})
        .then(res => {
          commit('PHYSICIAN_PROTOCOLS_SET_PER_PAGE', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  addPhysicianProtocol({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post('physician-protocol/', payload)
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  editPhysicianProtocol({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`physician-protocol/${item.id}/`, item)
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  getPhysicianProtocol({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.get(`physician-protocol/${id}/`)
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  deletePhysicianProtocol({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`physician-protocol/${id}/`)
        .then(res => {
          commit('PHYSICIAN_PROTOCOL_DELETE', id)
          resolve(res)
        }).catch(e => reject({message: e}))
    })
  },
  copyPhysicianProtocol({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({url: 'copy-physician-protocol/', data: data, method: 'POST'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Analyses Synevo
  fetchAnalysesSynevoPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `analyses-synevo/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('ANALYSES_SYNEVO_SET_PER_PAGE', res.data.results)
          commit('ANALYSES_SYNEVO_SET_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        }).catch(error => {
        reject({message: error})
      })
    })
  },
  // Treatment features
  fetchTreatmentFeaturesPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `treatment-feature/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_TREATMENT_FEATURES_PER_PAGE', res.data.results)
          commit('SET_TREATMENT_FEATURES_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getTreatmentFeaturesList({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'treatment-feature-list/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_TREATMENT_FEATURES_FOR_DROPDOWN', res.data)
          resolve(res)
        })
        .catch(error => {
          reject({message: error})
        })
    })
  },
  addTreatmentFeature({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('treatment-feature/', item)
        .then(res => {
          commit('TREATMENT_FEATURE_ADD', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editTreatmentFeature({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`treatment-feature/${item.id}/`, item)
        .then(res => {
          commit('TREATMENT_FEATURE_EDIT', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteTreatmentFeature({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`treatment-feature/${id}/`)
        .then(res => {
          commit('TREATMENT_FEATURE_DELETE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // User service for time property
  getUserServiceForTimeProperty({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `user-service-for-time-property/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_USER_SERVICE_FOR_TIME_PROPERTY_PER_PAGE', res.data.results)
          commit('SET_USER_SERVICE_FOR_TIME_PROPERTY_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addUserServiceForTimeProperty({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('user-service-for-time-property/', item)
        .then(res => {
          commit('USER_SERVICE_FOR_TIME_PROPERTY_ADD', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editUserServiceForTimeProperty({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`user-service-for-time-property/${item.id}/`, item)
        .then(res => {
          commit('USER_SERVICE_FOR_TIME_PROPERTY_EDIT', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteUserServiceForTimeProperty({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`user-service-for-time-property/${id}/`)
        .then(res => {
          commit('USER_SERVICE_FOR_TIME_PROPERTY_DELETE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // User dental service property
  getUserDentalServiceProperty({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `user-dental-service-property/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_USER_DENTAL_SERVICE_PROPERTY_PER_PAGE', res.data.results)
          commit('SET_USER_DENTAL_SERVICE_PROPERTY_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addUserDentalServiceProperty({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('user-dental-service-property/', item)
        .then(res => {
          commit('USER_DENTAL_SERVICE_PROPERTY_ADD', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editUserDentalServiceProperty({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`user-dental-service-property/${item.id}/`, item)
        .then(res => {
          commit('USER_DENTAL_SERVICE_PROPERTY_EDIT', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteUserDentalServiceProperty({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`user-dental-service-property/${id}/`)
        .then(res => {
          commit('USER_DENTAL_SERVICE_PROPERTY_DELETE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Patient appointment status
  getPatientAppointmentStatusPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patient-appointment-status/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PATIENT_APPOINTMENT_STATUS_PER_PAGE', res.data.results)
          commit('SET_PATIENT_APPOINTMENT_STATUS_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getPatientAppointmentStatus({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'patient-appointment-status/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PATIENT_APPOINTMENT_STATUS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  removePatientAppointmentStatus({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`patient-appointment-status/${id}/`)
        .then(res => {
          commit('REMOVE_PATIENT_APPOINTMENT_STATUS', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addPatientAppointmentStatus({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-appointment-status/`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: data
      })
        .then(res => {
          commit('ADD_PATIENT_APPOINTMENT_STATUS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editPatientAppointmentStatus({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-appointment-status/${data.get('id')}/`,
        method: 'PATCH',
        headers: {'Content-Type': 'multipart/form-data'},
        data: data
      })
        .then(res => {
          commit('EDIT_PATIENT_APPOINTMENT_STATUS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  changePatientAppointmentStatusIsMainAttr({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-appointment-status/${data.id}/is-main`,
        method: 'PUT',
        data: data
      })
        .then(res => {
          commit('EDIT_PATIENT_APPOINTMENT_STATUS_IS_MAIN_ATTR', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  changePatientAppointmentStatusIsMainConfirmationLinkAttr({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-appointment-status/${data.id}/is-main-confirmation-link`,
        method: 'PUT',
        data: data
      })
        .then(res => {
          commit('EDIT_PATIENT_APPOINTMENT_STATUS_IS_MAIN_CONFIRMATION_LINK_ATTR', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  changePatientAppointmentStatusIsMainCancelLinkAttr({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-appointment-status/${data.id}/is-main-cancel-link`,
        method: 'PUT',
        data: data
      })
        .then(res => {
          commit('EDIT_PATIENT_APPOINTMENT_STATUS_IS_MAIN_CANCEL_LINK_ATTR', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  changePatientAppointmentStatusIsMainMoveLinkAttr({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `patient-appointment-status/${data.id}/is-main-move-link`,
        method: 'PUT',
        data: data
      })
        .then(res => {
          commit('EDIT_PATIENT_APPOINTMENT_STATUS_IS_MAIN_MOVE_LINK_ATTR', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Treatment plan
  getTreatmentPlanExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `treatment-plan-export/`, method: 'GET', params: params, responseType: 'blob'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // User treatment plan property
  getUserTreatmentPlanProperty({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `user-treatment-plan-property/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_USER_TREATMENT_PLAN_PROPERTY_PER_PAGE', res.data.results)
          commit('SET_USER_TREATMENT_PLAN_PROPERTY_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addUserTreatmentPlanProperty({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('user-treatment-plan-property/', item)
        .then(res => {
          commit('USER_TREATMENT_PLAN_PROPERTY_ADD', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editUserTreatmentPlanProperty({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`user-treatment-plan-property/${item.id}/`, item)
        .then(res => {
          commit('USER_TREATMENT_PLAN_PROPERTY_EDIT', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteUserTreatmentPlanProperty({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`user-treatment-plan-property/${id}/`)
        .then(res => {
          commit('USER_TREATMENT_PLAN_PROPERTY_DELETE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // System modules
  fetchSystemModulesAsTree({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `system-module-tree/`,
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // User api activity log items
  fetchUserActivityLogItemsPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `user-api-request-log/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_USER_ACTIVITY_LOG_ITEMS_PER_PAGE', res.data.results)
          commit('SET_USER_ACTIVITY_LOG_ITEMS_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Pricing type groups
  fetchPricingTypeGroups({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `pricing-type-group/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_PRICING_TYPE_GROUPS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addPricingTypeGroup({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('pricing-type-group/', item)
        .then(res => {
          commit('ADD_PRICING_TYPE_GROUP', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deletePricingTypeGroup({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`pricing-type-group/${id}/`)
        .then(res => {
          commit('REMOVE_PRICING_TYPE_GROUP', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  fetchGroupTypes({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `pricing-type/?group_id=${data.groupId}`,
        method: 'GET',
        params: data.queryParams,
      })
        .then(res => {
          commit('SET_PRICING_TYPE_GROUP_ITEMS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addPricingType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('pricing-type/', item)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  editPricingType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`pricing-type/${item.id}/`, item)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  deletePricingType({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`pricing-type/${id}/`)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getOverheadCostsTotal({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `overhead-costs-total/`,
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // User request
  sendUserRequest({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `user-request/`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: item
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // FAQ
  fetchFaqQuestions({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `faq/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_FAQ_QUESTIONS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addFaqQuestion({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `faq/`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: item
      })
        .then(res => {
          commit('ADD_FAQ_QUESTION', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editFaqQuestion({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `faq/${item.get('id')}/`,
        method: 'PATCH',
        headers: {'Content-Type': 'multipart/form-data'},
        data: item
      })
        .then(res => {
          commit('EDIT_FAQ_QUESTION', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteFaqQuestion({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`faq/${id}/`)
        .then(res => {
          commit('FAQ_QUESTION_DELETE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Medical service type
  getMedicalServiceTypeForDropdown({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'medical-service-type-for-dropdown/',
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getMedicalServiceTypePerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'medical-service-type/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_MEDICAL_SERVICE_TYPE_PER_PAGE', res.data.results)
          commit('SET_MEDICAL_SERVICE_TYPE_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addMedicalServiceType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('medical-service-type/', item)
        .then(res => {
          commit('ADD_MEDICAL_SERVICE_TYPE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editMedicalServiceType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`medical-service-type/${item.id}/`, item)
        .then(res => {
          commit('EDIT_MEDICAL_SERVICE_TYPE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  removeMedicalServiceType({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`medical-service-type/${id}/`)
        .then(res => {
          commit('REMOVE_MEDICAL_SERVICE_TYPE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Specialist type
  getSpecialistTypes({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'specialist-type-for-dropdown/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_SPECIALIST_TYPES', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getSpecialistTypesPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'specialist-type/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_SPECIALIST_TYPES_PER_PAGE', res.data.results)
          commit('SET_SPECIALIST_TYPES_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addSpecialistType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('specialist-type/', item)
        .then(res => {
          commit('ADD_SPECIALIST_TYPE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editSpecialistType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`specialist-type/${item.id}/`, item)
        .then(res => {
          commit('EDIT_SPECIALIST_TYPE', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  removeSpecialistType({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`specialist-type/${id}/`)
        .then(res => {
          commit('REMOVE_SPECIALIST_TYPE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  changeSpecialistTypeHiddenAttr({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `specialist-type/${data.id}/hidden`,
        method: 'PUT',
        data: data
      })
        .then(res => {
          commit('EDIT_SPECIALIST_TYPE_HIDDEN_ATTR', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Dental service specialist type property
  getDentalServiceSpecialistTypeProperty({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dental-service-specialist-type-property/`,
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_DENTAL_SERVICE_SPECIALIST_TYPE_PROPERTY_PER_PAGE', res.data.results)
          commit('SET_DENTAL_SERVICE_SPECIALIST_TYPE_PROPERTY_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addDentalServiceSpecialistTypeProperty({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dental-service-specialist-type-property/', item)
        .then(res => {
          commit('DENTAL_SERVICE_SPECIALIST_TYPE_PROPERTY_ADD', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editDentalServiceSpecialistTypeProperty({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dental-service-specialist-type-property/${item.id}/`, item)
        .then(res => {
          commit('DENTAL_SERVICE_SPECIALIST_TYPE_PROPERTY_EDIT', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteDentalServiceSpecialistTypeProperty({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`dental-service-specialist-type-property/${id}/`)
        .then(res => {
          commit('DENTAL_SERVICE_SPECIALIST_TYPE_PROPERTY_DELETE', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  // Specialist type wage deduction amount
  getSpecialistTypeWageDeductionsAmount({commit}, params) {
    return new Promise((resolve, reject) => {
      const id = params.specialist_type_id
      delete params.specialist_type_id
      axios({url: `specialist-type/${id}/wage-deduction-amount`, method: 'GET', params: params})
        .then(res => {
          commit('SET_SPECIALIST_TYPE_WAGE_DEDUCTIONS_AMOUNT_PER_PAGE', res.data.results)
          commit('SET_SPECIALIST_TYPE_WAGE_DEDUCTIONS_AMOUNT_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  addSpecialistTypeWageDeductionAmount({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post(`specialist-type/${item.specialist_type_id}/wage-deduction-amount`, item)
        .then(res => {
          commit('SPECIALIST_TYPE_WAGE_DEDUCTION_AMOUNT_ADD', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editSpecialistTypeWageDeductionAmount({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`specialist-type/${item.specialist_type_id}/wage-deduction-amount/${item.id}`, item)
        .then(res => {
          commit('SPECIALIST_TYPE_WAGE_DEDUCTION_AMOUNT_EDIT', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  deleteSpecialistTypeWageDeductionAmount({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.delete(`specialist-type/${item.specialist_type_id}/wage-deduction-amount/${item.id}`)
        .then(res => {
          commit('SPECIALIST_TYPE_WAGE_DEDUCTION_AMOUNT_DELETE', item.id)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Wage deduction types
  getWageDeductionTypes() {
    return new Promise((resolve, reject) => {
      axios({url: `dictionary/wage-deduction-type/`, method: 'GET'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Payment method categories
  getPaymentMethodCategories() {
    return new Promise((resolve, reject) => {
      axios({url: `dictionary/payment-method-category/`, method: 'GET'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Material price list
  addMaterialPriceList({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('material-price-list/', item)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  editMaterialPriceList({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.patch(
        `material-price-list/${data.item.id}/`,
        {
          date: data.date
        }
      )
        .then(res => {
          data.item.date = data.date
          data.item.unixDate = data.unixDate
          commit('UPDATE_MATERIAL_PRICE_LIST', data.item)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  confirmMaterialPriceList({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `material-price-list/${data.id}/confirmed`,
        method: 'PUT',
        data: data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  fetchMaterialPriceList({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'material-price-list/',
        method: 'GET'
      })
        .then(res => {
          const items = res.data.map(item => {
            item.selected = !!item.selected
            item.unixDate = item.date
            return item
          })
          const itemIndex = items.findIndex(item => !item.confirmed)
          let currentDate = moment().startOf('day')
          let currentDateIso = currentDate.toISOString()
          let currentDateUnix = currentDate.unix()
          let item = null
          if (itemIndex === -1) {
            const index = items.findIndex(item => moment().startOf('day').unix() <= item.date / 1000)
            if (index > -1) {
              currentDateIso = moment(parseInt(items[index].date)).add(1, 'days').toISOString()
            }
            dispatch('addMaterialPriceList', {date: currentDateIso}).then(result => {
              item = {
                confirmed: false,
                selected: true,
                unixDate: result.data.date,
                date: currentDateIso,
                id: -1
              }
              item.id = result.data.id
              items.unshift(item)
              commit('SET_MATERIAL_PRICE_LIST', items)
            })
          } else {
            item = items[itemIndex]
            if (currentDateUnix > item.date / 1000) {
              dispatch('editMaterialPriceList', {date: currentDateIso, unixDate: currentDateUnix, item})
            }
            item.selected = true
            item.unixDate = item.date
            item.date = moment(parseInt(item.date)).toISOString()
          }
          commit('SET_MATERIAL_PRICE_LIST', items)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteMaterialPriceList({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`material-price-list/${id}/`)
        .then(res => {
          commit('DELETE_MATERIAL_PRICE_LIST', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  getMaterialPriceListItems({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `material-price-list/${params.id}/item`,
        method: 'GET',
        params: params.queryParams,
      })
        .then(res => {
          commit('SET_MATERIAL_PRICE_LIST_ITEMS', res.data.results)
          commit('SET_MATERIAL_PRICE_LIST_ITEMS_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editMaterialPriceListItem({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`material-price-list-item/${item.id}/`, item)
        .then(res => {
          commit('UPDATE_MATERIAL_PRICE_LIST_ITEM', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteMaterialPriceListItem({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`material-price-list-item/${id}/`)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  addMaterialPriceListItems({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post(`material-price-list/bulk-item`, data)
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  removeMaterialPriceListItems({commit}, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'material-price-list/bulk-item', method: 'DELETE', data: data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  getMaterialLotWaybillsForDropdown({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'material-lot-waybill-for-dropdown/',
        method: 'GET',
        params: params
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  // Integrator Branch
  getIntegratorBranchPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'dictionary/integrator-branch/',
        method: 'GET',
        params: params
      })
        .then(res => {
          commit('SET_INTEGRATOR_BRANCHES_PER_PAGE', res.data.results)
          commit('SET_INTEGRATOR_BRANCHES_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  addIntegratorBranch({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dictionary/integrator-branch/', item)
        .then(res => {
          commit('ADD_INTEGRATOR_BRANCH', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  editIntegratorBranch({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dictionary/integrator-branch/${item.id}/`, item)
        .then(res => {
          commit('EDIT_INTEGRATOR_BRANCH', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  deleteIntegratorBranch({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary/integrator-branch/${id}/`,
        method: 'DELETE'
      })
        .then(res => {
          commit('REMOVE_INTEGRATOR_BRANCH', id)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  changeIntegratorBranchEnabledState({commit}, item) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary/integrator-branch/${item.id}/enabled`,
        method: 'PUT',
        data: item
      })
        .then(res => {
          commit('INTEGRATOR_BRANCH_CHANGE_ENABLE_STATE', item)
          resolve(res)
        })
        .catch(error => {
          commit('INTEGRATOR_BRANCH_CHANGE_ENABLE_STATE', {id: item.id, enabled: !item.enabled})
          reject({message: error})
        })
    })
  },
  onBranchLogic({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${id}/branch-logic-on`,
        method: 'PATCH',
      })
        .then(res => {
          commit('BRANCH_LOGIC_ENABLE', id)
          resolve(res)
        })
        .catch(error => {
          commit('BRANCH_LOGIC_DISABLE', id)
          reject({message: error})
        })
    })
  },
  offBranchLogic({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${id}/branch-logic-off`,
        method: 'PATCH',
      })
        .then(res => {
          commit('BRANCH_LOGIC_DISABLE', id)
          resolve(res)
        })
        .catch(error => {
          commit('BRANCH_LOGIC_ENABLE', id)
          reject({message: error})
        })
    })
  },
  // Integrator ATE module
  onAteModule({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${id}/ate-module-on`,
        method: 'PATCH',
      })
        .then(res => {
          commit('BRANCH_ATE_MODULE_ENABLE', id)
          resolve(res)
        })
        .catch(error => {
          commit('BRANCH_ATE_MODULE_DISABLE', id)
          reject({message: error})
        })
    })
  },
  offAteModule({commit}, id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${id}/ate-module-off`,
        method: 'PATCH',
      })
        .then(res => {
          commit('BRANCH_ATE_MODULE_DISABLE', id)
          resolve(res)
        })
        .catch(error => {
          commit('BRANCH_ATE_MODULE_ENABLE', id)
          reject({message: error})
        })
    })
  },
  // Integrator settings
  getIntegratorSettings({commit}, integratorId) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator-settings/${integratorId}/`,
        method: 'GET'
      })
        .then(res => {
          commit('SET_INTEGRATOR_SETTINGS', res.data)
          resolve(res)
        })
        .catch(error => reject({message: error}))
    })
  },
  saveIntegratorMainSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${params.id}/main-settings`,
        method: 'PATCH',
        data: params.data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  saveIntegratorDentalLaboratorySettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${params.id}/dental-laboratory-settings`,
        method: 'PATCH',
        data: params.data
      })
        .then(res => resolve(res))
        .catch(error => reject({message: error}))
    })
  },
  saveIntegratorSubscriptionSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${params.id}/subscription-settings`,
        method: 'PATCH',
        data: params.data
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  saveIntegratorServiceCalculationSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: `integrator/${params.id}/service-calculation-settings`,
        method: 'PATCH',
        data: params.data
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  saveIntegratorTreatmentPresentationSettings({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `integrator/${params.id}/treatment-presentation-settings`, method: 'PATCH', data: params.data})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Service discount types
  getServiceDiscountTypes({commit}) {
    return new Promise((resolve, reject) => {
      axios({
        url: `dictionary/service-discount-type/`,
        method: 'GET'
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Recommended visit type
  fetchRecommendedVisitTypesPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `dictionary/recommended-visit-type/`, method: 'GET', params: params})
        .then(res => {
          commit('SET_RECOMMENDED_VISIT_TYPES', res.data.results)
          commit('SET_RECOMMENDED_VISIT_TYPES_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  fetchRecommendedVisitTypesForDropdown({commit}) {
    return new Promise((resolve, reject) => {
      axios({url: `dictionary/recommended-visit-type/`, method: 'GET'})
        .then(res => {
          commit('SET_RECOMMENDED_VISIT_TYPES', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  addRecommendedVisitType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dictionary/recommended-visit-type/', item)
        .then(res => {
          commit('RECOMMENDED_VISIT_TYPE_ADD', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editRecommendedVisitType({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dictionary/recommended-visit-type/${item.id}/`, item)
        .then(res => {
          commit('RECOMMENDED_VISIT_TYPE_EDIT', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  deleteRecommendedVisitType({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`dictionary/recommended-visit-type/${id}/`)
        .then(res => {
          commit('RECOMMENDED_VISIT_TYPE_DELETE', id)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Counterparties
  fetchCounterpartiesPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `dictionary/counterparty/`, method: 'GET', params: params})
        .then(res => {
          commit('SET_COUNTERPARTIES', res.data.results)
          commit('SET_COUNTERPARTIES_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  fetchCounterparties({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `dictionary/counterparty/`, method: 'GET', params: params})
        .then(res => {
          commit('SET_COUNTERPARTIES', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  addCounterparty({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dictionary/counterparty/', item)
        .then(res => {
          commit('COUNTERPARTY_ADD', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editCounterparty({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dictionary/counterparty/${item.id}/`, item)
        .then(res => {
          commit('COUNTERPARTY_EDIT', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  deleteCounterparty({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`dictionary/counterparty/${id}/`)
        .then(res => {
          commit('COUNTERPARTY_DELETE', id)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getCounterpartiesForDropdown({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'dictionary/counterparty-for-dropdown/', method: 'GET', params: params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Tooth state
  getTeethStateTypes({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `dictionary/tooth-state-type/`, method: 'GET', params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  fetchTeethStatesPerPage({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `dictionary/tooth-state/`, method: 'GET', params})
        .then(res => {
          commit('SET_TEETH_STATES', res.data.results)
          commit('SET_TEETH_STATES_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  fetchTeethStatesForDropdown({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `dictionary/tooth-state/`, method: 'GET', params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  addToothState({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post('dictionary/tooth-state/', item)
        .then(res => {
          commit('TOOTH_STATE_ADD', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  editToothState({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.patch(`dictionary/tooth-state/${item.id}/`, item)
        .then(res => {
          commit('TOOTH_STATE_EDIT', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  deleteToothState({commit}, id) {
    return new Promise((resolve, reject) => {
      axios.delete(`dictionary/tooth-state/${id}/`)
        .then(res => {
          commit('TOOTH_STATE_DELETE', id)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getTeethStateCategories({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `dictionary/tooth-state-category/`, method: 'GET', params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Tooth state services
  getToothStateServices({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `tooth-state/${params.toothStateId}/service`, method: 'GET', params: params})
        .then(res => {
          commit('SET_TOOTH_STATE_SERVICES_PER_PAGE', res.data.results)
          commit('SET_TOOTH_STATE_SERVICES_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  addToothStateServices({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`tooth-state/${data.tooth_state_id}/service`, data)
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  deleteToothStateService({commit}, params) {
    return new Promise((resolve, reject) => {
      axios.delete(`tooth-state/${params.toothStateId}/service/${params.id}`)
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Tooth state complexes
  getToothStateComplexes({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `tooth-state/${params.toothStateId}/complex`, method: 'GET', params: params})
        .then(res => {
          commit('SET_TOOTH_STATE_COMPLEXES_PER_PAGE', res.data.results)
          commit('SET_TOOTH_STATE_COMPLEXES_PER_PAGE_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  addToothStateComplexes({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios.post(`tooth-state/${data.tooth_state_id}/complex`, data)
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  deleteToothStateComplex({commit}, params) {
    return new Promise((resolve, reject) => {
      axios.delete(`tooth-state/${params.toothStateId}/complex/${params.id}`)
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
}
