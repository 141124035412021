export default {
  reportForm039: [],
  reportForm039_3: [],
  reportForm037: [],
  outpatientSurgeries: [],
  paidServicesJournal: [],
  paidServicesJournalCount: 0,
  paidServicesJournalTotal: [],
  certificatesWorkPerformed : [],
  certificatesWorkPerformedCount : 0,
  certificatesWorkPerformedTotal : [],
  paidMedicalServicesInformation : []
}
