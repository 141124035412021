import store from '@/store'
import moment from 'moment/moment'
import useDiscountCard from '@/composables/discountCards'

export const getPatientDocumentInfo = patient => {
  const docTypes = store.getters['patient/getDocumentTypes']
  if (patient.document_type && patient.document_type === 'passport' && patient.document_identifier) {
    const doc = docTypes.find(dt => dt.value === patient.document_type)
    if (doc) {
      const info = [doc.text, patient.document_identifier]
      if (patient.document_issue_date || patient.document_issued_by) {
        info.push('Выдан')
        if (patient.document_issue_date) {
          info.push(moment(patient.document_issue_date).format('DD.MM.YYYY'))
        }
        if (patient.document_issued_by) {
          info.push(patient.document_issued_by)
        }
      }
      return info
    }
  }
  return null
}

export default function usePatient() {
  const { discountCards } = useDiscountCard()

  const getPatientDiscountCardByCardId = discountCardId => discountCards.value.find(dc => dc.id === discountCardId)

  return {
    getPatientDiscountCardByCardId
  }
}
