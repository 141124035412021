const entityPatient = 'patient'
const entityGroup = 'group'
const entityBite = 'bite'
const entityContacts = 'contacts'
const entityDentalservicesreference = 'dentalservicesreference'
const entityDiagnosis = 'diagnosis'
const entityDiscountcards = 'discountcards'
const entityServiceTime = 'servicesfortime'
const entityPatientType = 'patienttype'
const entityPaymentDirection = 'paymentdirection'
const entityMaterial = 'material'
const entityMaterialunittype = 'materialunittype'
const entityPaymentmethod = 'paymentmethod'
const entityTreatmentplans = 'treatmentplans'
const entityUser = 'user'
const entityDictionaryproducts = 'dictionaryproducts'
const entityDictionarysuppliers = 'dictionarysuppliers'
const entityDentalFile = 'dentalfile'
const entityDentalStatusTeeth = 'dentalstatusteeth'
const entityGeneralTreatmentPlan = 'generaltreatmentplan'
const entityPatientCommonDiseases = 'patientcommondiseases'
const entityPatientVisualInspection = 'patientvisualinspection'
const entityPatientAdvisoryOpinion = 'patientadvisoryopinion'
const entityTreatmentDiary = 'treatmentdiary'
const entityTreatmentdiarydentalservices = 'treatmentdiarydentalservices'
const entityTask = 'task'
const entityIntegrator = 'integrator'
const entityIntegratorRoot = 'integrator-root'
const entityProducts = 'products'
const entitySuppliers = 'suppliers'
const entityOrder = 'order'
const entityOrderBucket = 'orderbucket'
const entityEmailSettings = 'emailsettings'
const entityDocumentSettings = 'documentsettings'
const entityPatientReceptionScheduleSettings = 'patientreceptionschedulesettings'
const entityCashRegisterSettings = 'cashregistersettings'
const entityHistoryOrders = 'historyorders'
const schedulerpatient = 'schedulerpatient'
const dictionarydesignationsscheduler = 'dictionarydesignationsscheduler'
const schedulercalendar = 'schedulercalendar'
const scheduler = 'scheduler'
const workshift = 'workshift'
const contactsfrom = 'contactsfrom'
const entityPatientPayment = 'patientpayment'
const entityDictionaryPatientTemplate = 'patienttemplategroup'
const entityAdvisoryOpinion = 'advisoryopinion'
const entityEmployeeLineOfWork = 'employeelineofwork'
const entityDirection = 'direction'
const entityImportExport = 'importexport'
const entityInsuranceCompany = 'insurancecompany'
const entityLegalPerson = 'legalperson'
const entityInsurance = 'insurance'
const entityChat = 'chat'
const entityChatMessage = 'chatmessage'
const entityLead = 'lead'
const entityPatientAppealResult = 'patientappealresult'
const entityDictionaryNotificationConditionCategory = 'dictionarynotificationconditioncategory'
const entityNotification = 'notification'
const entityDictionaryTreatmentFeature = 'dictionarytreatmentfeature'
const entityDictionaryNds = 'nds'
const entityPatientOutpatientSurgery = 'patientoutpatientsurgery'
const entityPricingTypeValue = 'pricingtypevalue'
const entityMaterialLot = 'materiallot'
const entityPriceSettings = 'pricesettings'
const entityPatientAppointmentStatus = 'patientappointmentstatus'
const entityTreatmentDiarySettings = 'treatmentdiarysettings'
const entityFaq = 'faq'
const entityEmployeeSettings = 'employeesettings'
const entityDepartment = 'department'
const entityMedicalServiceType = 'medicalservicetype'
const entitySpecialistType = 'specialisttype'
const entityMaterialPriceList = 'materialpricelist'
const entityDentalServiceSettings = 'dentalservicesettings'
const entityAdvertisingPlatformUtmSource = 'advertisingplatformutmsource'
const entityPatientCardSettings = 'patientcardsettings'
const entityAdvertisingPlatformUtmMedium = 'advertisingplatformutmmedium'
const entityPurchaseOrder = 'purchaseorder'
const entityImplantSystem = 'implantsystem'
const entityImplantWorkType = 'implantworktype'
const entityMaterialType = 'materialtype'
const entityWorkType = 'worktype'
const entityIntegratorBranch = 'integratorbranch'
const entityPatientCall = 'patientcall'
const entityColorPalette = 'colorpalette'
const entityThirdPartyOrganization = 'thirdpartyorganization'
const entityCertificate = 'certificate'
const entityBillingStatement = 'billingstatement'
const entityEmployeeWorkDay = 'employeeworkday'
const entityRecommendedVisitType = 'recommendedvisittype'
const entityPatientRecommendedVisit = 'patientrecommendedvisit'
const entityDictionaryCounterparty = 'dictionarycounterparty'
const entityTreatmentPresentation = 'treatmentpresentation'
const entityToothState = 'toothstate'
const entityToothStateService = 'toothstateservice'
const entityToothStateComplex = 'toothstatecomplex'
const entityTreatmentPresentationSettings = 'treatmentpresentationsettings'
const entityLeadSettings = 'leadsettings'

export {
  entityPatient,
  entityGroup,
  entityBite,
  entityContacts,
  entityDentalservicesreference,
  entityDiagnosis,
  entityDiscountcards,
  entityServiceTime,
  entityMaterial,
  entityMaterialunittype,
  entityPaymentmethod,
  entityTreatmentplans,
  entityUser,
  entityDictionaryproducts,
  entityDictionarysuppliers,
  entityDentalFile,
  entityDentalStatusTeeth,
  entityGeneralTreatmentPlan,
  entityPatientCommonDiseases,
  entityPatientVisualInspection,
  entityTreatmentDiary,
  entityTreatmentdiarydentalservices,
  entityTask,
  entityIntegrator,
  entityIntegratorRoot,
  entityOrder,
  entitySuppliers,
  entityProducts,
  entityEmailSettings,
  entityOrderBucket,
  entityHistoryOrders,
  schedulerpatient,
  dictionarydesignationsscheduler,
  schedulercalendar,
  scheduler,
  workshift,
  contactsfrom,
  entityPatientPayment,
  entityPatientType,
  entityDictionaryPatientTemplate,
  entityPaymentDirection,
  entityAdvisoryOpinion,
  entityPatientAdvisoryOpinion,
  entityEmployeeLineOfWork,
  entityDirection,
  entityImportExport,
  entityInsuranceCompany,
  entityInsurance,
  entityChat,
  entityChatMessage,
  entityLegalPerson,
  entityLead,
  entityPatientAppealResult,
  entityDictionaryNotificationConditionCategory,
  entityNotification,
  entityDocumentSettings,
  entityPatientReceptionScheduleSettings,
  entityCashRegisterSettings,
  entityDictionaryTreatmentFeature,
  entityDictionaryNds,
  entityPatientOutpatientSurgery,
  entityPricingTypeValue,
  entityMaterialLot,
  entityPriceSettings,
  entityPatientAppointmentStatus,
  entityTreatmentDiarySettings,
  entityFaq,
  entityEmployeeSettings,
  entityDepartment,
  entityMedicalServiceType,
  entitySpecialistType,
  entityMaterialPriceList,
  entityDentalServiceSettings,
  entityAdvertisingPlatformUtmSource,
  entityPatientCardSettings,
  entityAdvertisingPlatformUtmMedium,
  entityPurchaseOrder,
  entityImplantSystem,
  entityImplantWorkType,
  entityMaterialType,
  entityWorkType,
  entityIntegratorBranch,
  entityPatientCall,
  entityColorPalette,
  entityThirdPartyOrganization,
  entityCertificate,
  entityBillingStatement,
  entityEmployeeWorkDay,
  entityRecommendedVisitType,
  entityPatientRecommendedVisit,
  entityDictionaryCounterparty,
  entityTreatmentPresentation,
  entityToothState,
  entityToothStateService,
  entityToothStateComplex,
  entityTreatmentPresentationSettings,
  entityLeadSettings
}
