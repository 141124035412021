import axios from '@/libs/axios'

export default {
  // Reports
  getReportForm039({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'report-form-039/', method: 'GET', params})
        .then(res => {
          commit('SET_REPORT_FORM_039', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getReportForm039_3({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'report-form-039-3/', method: 'GET', params})
        .then(res => {
          commit('SET_REPORT_FORM_039_3', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getReportForm037({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'report-form-037/' + params.doctor_id + '/', method: 'GET', params: params.query_params})
        .then(res => {
          commit('SET_REPORT_FORM_037', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  exportReportForm037({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({
        url: 'report-form-037-export/' + params.doctor_id + '/',
        method: 'GET',
        params: params.query_params,
        responseType: 'blob'
      })
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  exportReportForm039({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'report-form-039-export/', method: 'GET', params, responseType: 'blob'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  exportReportForm039_3({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'report-form-039-3-export/', method: 'GET', params, responseType: 'blob'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Outpatient Surgeries
  getOutpatientSurgeries({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'outpatient-surgery/', method: 'GET', params})
        .then(res => {
          commit('SET_OUTPATIENT_SURGERIES', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getOutpatientSurgeriesExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `outpatient-surgery-export/`, method: 'GET', params, responseType: 'blob'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Journal of paid services
  getJournalOfPaidServices({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'paid-services-journal/', method: 'GET', params})
        .then(res => {
          commit('SET_JOURNAL_OF_PAID_SERVICES', res.data.results)
          commit('SET_JOURNAL_OF_PAID_SERVICES_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getJournalOfPaidServicesExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `paid-services-journal-export/`, method: 'GET', params, responseType: 'blob'})
        .then(res => resolve(res))
        .catch(e => e({message: e}))
    })
  },
  getJournalOfPaidServicesTotal({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'paid-services-journal-total/', method: 'GET', params})
        .then(res => {
          commit('SET_JOURNAL_OF_PAID_SERVICES_TOTAL', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Certificates work performed
  getCertificatesWorkPerformed({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'certificates-work-performed/', method: 'GET', params})
        .then(res => {
          commit('SET_CERTIFICATES_WORK_PERFORMED', res.data.results)
          commit('SET_CERTIFICATES_WORK_PERFORMED_COUNT', res.data.count)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getCertificatesWorkPerformedExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `certificates-work-performed-export/`, method: 'GET', params, responseType: 'blob'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  getCertificatesWorkPerformedTotal({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'certificates-work-performed-total/', method: 'GET', params})
        .then(res => {
          commit('SET_CERTIFICATES_WORK_PERFORMED_TOTAL', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  // Paid medical services information
  getPaidMedicalServicesInformation({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'paid-medical-services-information/', method: 'GET', params})
        .then(res => {
          commit('SET_PAID_MEDICAL_SERVICES_INFORMATION', res.data)
          resolve(res)
        })
        .catch(e => reject({message: e}))
    })
  },
  getPaidMedicalServicesInformationExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `paid-medical-services-information-export/`, method: 'GET', params, responseType: 'blob'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // List of materials in bill of lading
  getWaybillMaterials({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'waybill-materials/', method: 'GET', params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Work performed (Dental laboratory)
  getWorkPerformedDL({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'work-performed-dl/', method: 'GET', params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  getWorkPerformedDLExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `work-performed-dl-export/`, method: 'GET', params, responseType: 'blob'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  // Potential calculation (Financial efficiency)
  getPotentialCalculation({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: 'reports/potential-calculation/', method: 'GET', params})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  },
  getPotentialCalculationExportExcel({commit}, params) {
    return new Promise((resolve, reject) => {
      axios({url: `reports/potential-calculation-export/`, method: 'GET', params, responseType: 'blob'})
        .then(res => resolve(res))
        .catch(e => reject({message: e}))
    })
  }
}
