export default {
  // Document settings
  documentSettings: [],
  // Patient reception schedule settings
  patientReceptionScheduleSettings: [],
  // Cash register settings
  cashRegisterSettings: [],
  // Price settings
  priceSettings: [],
  // Treatment diary settings
  treatmentDiarySettings: [],
  treatmentDiarySortTypeOptions: [
    {
      value: 'asc',
      text: 'От первого к последнему'
    },
    {
      value: 'desc',
      text: 'От последнего к первому'
    }
  ],
  // Employee settings
  employeeSettings: [],
  // Dental service settings
  dentalServiceSettings: [],
  // Patient card settings
  patientCardSettings: [],
  // Treatment presentation settings
  treatmentPresentationSettings: [],
  // Lead settings
  leadSettings: [],
}
