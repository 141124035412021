import {
  entityBite, entityContacts, entityDentalservicesreference, entityDiagnosis, entityDiscountcards, entityIntegratorRoot,
  entityMaterial, entityPaymentmethod, entityGroup, entityTreatmentplans, entityUser, entityMaterialunittype,
  entityPatientType, contactsfrom, entityServiceTime, entityDictionaryPatientTemplate, entityPaymentDirection,
  entityAdvisoryOpinion, entityEmployeeLineOfWork, entityDirection, entityImportExport, entityInsuranceCompany,
  entityLegalPerson, entityPatientAppealResult, entityDictionaryNotificationConditionCategory, entityEmailSettings,
  entityDocumentSettings, entityPatientReceptionScheduleSettings, entityCashRegisterSettings,
  entityDictionaryTreatmentFeature, entityDictionaryNds, entityPricingTypeValue, entityPriceSettings,
  entityPatientAppointmentStatus, entityTreatmentDiarySettings, entityEmployeeSettings, entityDepartment,
  entityMedicalServiceType, entitySpecialistType, entityMaterialPriceList, entityAdvertisingPlatformUtmSource,
  entityPatientCardSettings, entityAdvertisingPlatformUtmMedium, entityImplantSystem, entityImplantWorkType,
  entityMaterialType, entityWorkType, entityIntegratorBranch, entityColorPalette, entityThirdPartyOrganization,
  entityRecommendedVisitType, entityDictionaryCounterparty, entityToothState, entityTreatmentPresentationSettings,
  entityLeadSettings
} from '@/libs/acl/entities'
import appConstants from '@/constants/constants'

export default [
  {
    path: '/dictionary/dental_services',
    name: 'dictionary-dental-services',
    component: () => import('@/views/dictionary/dentalServices/DentalService.vue'),
    meta: {
      resource: entityDentalservicesreference,
      action: 'read',
      title: 'DentalServices'
    }
  },
  {
    path: '/dictionary/dental_service_create',
    name: 'DentalServiceCreate',
    props: true,
    component: () => import('@/views/dictionary/dentalServices/ServiceForm'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список услуг', url: '/dictionary/dental_services' },
        { title: 'Создать услугу', active: true }
      ],
      pageTitle: 'Создание услуги',
      rule: 'editor',
      resource: entityDentalservicesreference,
      action: 'create',
      title: 'Service creation'
    }
  },
  {
    path: '/dictionary/dental_service_edit/:serviceId',
    name: 'DentalServiceEdit',
    component: () => import('@/views/dictionary/dentalServices/ServiceForm'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список услуг', url: '/dictionary/dental_services' },
        { title: 'Редактировать услугу', active: true }
      ],
      pageTitle: 'Редактирование услуги',
      rule: 'editor',
      resource: entityDentalservicesreference,
      action: 'update',
      title: 'Service edit'
    }
  },
  {
    path: '/dictionary/treatment-plans',
    name: 'dictionary-treatment-plans',
    component: () => import('@/views/dictionary/plans/TreatmentPlan.vue'),
    meta: {
      resource: entityTreatmentplans,
      action: 'read',
      title: 'Complexes'
    }
  },
  {
    path: '/dictionary/create-plan',
    name: 'app-plan-create',
    props: true,
    component: () => import('@/views/dictionary/plans/PlanForm.vue'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список комплексов', url: '/dictionary/treatment-plans' },
        { title: 'Создать комплекс', active: true }
      ],
      pageTitle: 'Создание комплекса',
      rule: 'editor',
      resource: entityTreatmentplans,
      action: 'create',
      title: 'Complex creation'
    }
  },
  {
    path: '/dictionary/edit-plan',
    name: 'app-plan-edit',
    props: true,
    component: () => import('@/views/dictionary/plans/PlanForm.vue'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список комплексов', url: '/dictionary/treatment-plans' },
        { title: 'Редактировать комплекс', active: true }
      ],
      pageTitle: 'Редактирование комплекса',
      rule: 'editor',
      resource: entityTreatmentplans,
      action: 'update',
      title: 'Complex edit'
    }
  },
  {
    path: '/dictionary/units',
    name: 'dictionary-units',
    component: () => import('@/views/dictionary/Units'),
    meta: {
      resource: entityMaterialunittype,
      action: 'read',
      title: 'Units',
    }
  },
  {
    path: '/dictionary/nds',
    name: 'dictionary-nds',
    component: () => import('@/views/dictionary/Nds'),
    meta: {
      resource: entityDictionaryNds,
      action: 'read',
      title: 'Nds',
    }
  },
  {
    path: '/dictionary/advisory-opinion',
    name: 'dictionary-advisory-opinion',
    component: () => import('@/views/dictionary/AdvisoryOpinion'),
    meta: {
      resource: entityAdvisoryOpinion,
      action: 'read',
      title: 'Advisory opinion'
    }
  },
  {
    path: '/dictionary/roles',
    name: 'dictionary-roles',
    component: () => import('@/views/dictionary/role/Role'),
    meta: {
      resource: entityGroup,
      action: 'read',
      title: 'Roles'
    }
  },
  {
    path: '/dictionary/role/usual-role-create',
    name: 'app-usual-role-create',
    component: () => import('@/views/dictionary/role/RoleCreate.vue'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список ролей', url: '/dictionary/roles' },
        { title: 'Создать роль', active: true }
      ],
      pageTitle: 'Создание роли',
      rule: 'editor',
      resource: entityGroup,
      action: 'create',
      title: 'Role creation',
      category: appConstants.role.category.USUAL
    }
  },
  {
    path: '/dictionary/role/service-role-create',
    name: 'app-service-role-create',
    component: () => import('@/views/dictionary/role/RoleCreate.vue'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список ролей', url: '/dictionary/roles' },
        { title: 'Создать роль', active: true }
      ],
      pageTitle: 'Создание роли',
      rule: 'editor',
      resource: entityGroup,
      action: 'create',
      title: 'Role creation',
      category: appConstants.role.category.SERVICE
    }
  },
  {
    path: '/dictionary/role/usual-role-edit/:userId',
    name: 'app-usual-role-edit',
    props: true,
    component: () => import('@/views/dictionary/role/RoleEdit'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список ролей', url: '/dictionary/roles' },
        { title: 'Редактировать роль', active: true }
      ],
      pageTitle: 'Редактирование роли',
      rule: 'editor',
      resource: entityGroup,
      action: 'update',
      title: 'Role edit',
      category: appConstants.role.category.USUAL
    }
  },
  {
    path: '/dictionary/role/service-role-edit/:userId',
    name: 'app-service-role-edit',
    props: true,
    component: () => import('@/views/dictionary/role/RoleEdit'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Список ролей', url: '/dictionary/roles' },
        { title: 'Редактировать роль', active: true }
      ],
      pageTitle: 'Редактирование роли',
      rule: 'editor',
      resource: entityGroup,
      action: 'update',
      title: 'Role edit',
      category: appConstants.role.category.SERVICE
    }
  },
  {
    path: '/dictionary/employee',
    name: 'dictionary-employee',
    component: () => import('@/views/dictionary/employee/Employee.vue'),
    meta: {
      resource: entityUser,
      action: 'read',
      title: 'Employee'
    }
  },
  {
    path: '/dictionary/type-contact',
    name: 'dictionary-type-contact',
    component: () => import('@/views/dictionary/Contacts'),
    meta: {
      resource: entityContacts,
      action: 'read',
      title: 'TypeContact'
    }
  },
  {
    path: '/dictionary/payment-method',
    name: 'dictionary-payment-method',
    component: () => import('@/views/dictionary/PaymentMethod'),
    meta: {
      resource: entityPaymentmethod,
      action: 'read',
      title: 'PaymentMethod'
    }
  },
  {
    path: '/dictionary/discount-cards',
    name: 'dictionary-discount-cards',
    component: () => import('@/views/dictionary/DiscountCards'),
    meta: {
      resource: entityDiscountcards,
      action: 'read',
      title: 'DiscountCards'
    }
  },
  {
    path: '/dictionary/materials',
    name: 'dictionary-materials',
    component: () => import('@/views/dictionary/materials/Materials.vue'),
    meta: {
      resource: entityMaterial,
      action: 'read',
      title: 'Materials'
    }
  },
  {
    path: '/dictionary/material-create',
    name: 'material-create',
    props: true,
    component: () => import('@/views/dictionary/materials/MaterialForm'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Материалы', url: '/dictionary/materials' },
        { title: 'Создание материала', active: true }
      ],
      pageTitle: 'Создание материала',
      resource: entityMaterial,
      action: 'create',
      title: 'Material creation'
    }
  },
  {
    path: '/dictionary/material-edit',
    name: 'material-edit',
    props: true,
    component: () => import('@/views/dictionary/materials/MaterialForm'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Материалы', url: '/dictionary/materials' },
        { title: 'Редактирование материала', active: true }
      ],
      pageTitle: 'Редактирование материала',
      resource: entityMaterial,
      action: 'update',
      title: 'Material edit'
    }
  },
  {
    path: '/dictionary/diagnosis',
    name: 'dictionary-diagnosis',
    component: () => import('@/views/dictionary/Diagnosis'),
    meta: {
      resource: entityDiagnosis,
      action: 'read',
      title: 'Diagnosis'
    }
  },
  {
    path: '/dictionary/diagnosis_create',
    name: 'DiagnosisCreate',
    props: true,
    component: () => import('@/views/dictionary/diagnosis/DiagnosisForm'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Диагнозы', url: '/dictionary/diagnosis' },
        { title: 'Создать диагноз', active: true }
      ],
      pageTitle: 'Создать диагноз',
      rule: 'editor',
      resource: entityDiagnosis,
      action: 'create',
      title: 'Diagnosis creation'
    }
  },
  {
    path: '/dictionary/diagnosis_edit',
    name: 'DiagnosisEdit',
    props: true,
    component: () => import('@/views/dictionary/diagnosis/DiagnosisForm'),
    meta: {
      breadcrumb: [
        { title: 'Главная', url: '/' },
        { title: 'Диагнозы', url: '/dictionary/diagnosis' },
        { title: 'Изменить диагноз', active: true }
      ],
      pageTitle: 'Изменение диагноза',
      rule: 'editor',
      resource: entityDiagnosis,
      action: 'update',
      title: 'Diagnosis edit'
    }
  },
  {
    path: '/dictionary/bites',
    name: 'dictionary-bites',
    component: () => import('@/views/dictionary/Bites'),
    meta: {
      resource: entityBite,
      action: 'read',
      title: 'Bites'
    }
  },
  {
    path: '/dictionary/integrator',
    name: 'dictionary-integrator',
    component: () => import('@/views/dictionary/Integrators'),
    meta: {
      resource: entityIntegratorRoot,
      action: 'read',
      title: 'Clinics'
    }
  },
  {
    path: '/dictionary/branch',
    name: 'dictionary-branch',
    component: () => import('@/views/dictionary/branch/Branch.vue'),
    meta: {
      resource: entityIntegratorBranch,
      action: 'read',
      title: 'Branches'
    }
  },
  {
    path: '/dictionary/contacts-from',
    name: 'contacts-from',
    component: () => import('@/views/dictionary/contactsFrom/ContactsFrom'),
    meta: {
      resource: contactsfrom,
      action: 'read',
      title: 'How contacted?'
    }
  },
  {
    path: '/dictionary/service-time',
    name: 'service-time',
    component: () => import('@/views/dictionary/ServiceTime/ServiceTime'),
    meta: {
      resource: entityServiceTime,
      action: 'read',
      title: 'Service time'
    }
  },
  {
    path: '/dictionary/patient-type',
    name: 'patient-type',
    component: () => import('@/views/dictionary/patient-type/PatientType'),
    meta: {
      resource: entityPatientType,
      action: 'read',
      title: 'Patient types'
    }
  },
  {
    path: '/dictionary/dictionary-patient-template',
    name: 'dictionary-patient-template',
    component: () => import('@/views/dictionary/patient-card-template/PatientCardTemplate'),
    meta: {
      resource: entityDictionaryPatientTemplate,
      action: 'read',
      title: 'Patient card templates'
    }
  },
  {
    path: '/dictionary/payment-direction',
    name: 'dictionary-payment-direction',
    component: () => import('@/views/dictionary/payment-direction/PaymentDirection'),
    meta: {
      resource: entityPaymentDirection,
      action: 'read',
      title: 'PaymentDirection'
    }
  },
  {
    path: '/dictionary/employee-line-of-work',
    name: 'dictionary-employee-line-of-work',
    component: () => import('@/views/dictionary/employee-line-of-work/EmployeeLineOfWork'),
    meta: {
      resource: entityEmployeeLineOfWork,
      action: 'read',
      title: 'Employees line of work'
    }
  },
  {
    path: '/dictionary/direction',
    name: 'dictionary-direction',
    component: () => import('@/views/dictionary/direction/Direction'),
    meta: {
      resource: entityDirection,
      action: 'read',
      title: 'Directions'
    }
  },
  {
    path: '/dictionary/department',
    name: 'dictionary-department',
    component: () => import('@/views/dictionary/department/Department'),
    meta: {
      resource: entityDepartment,
      action: 'read',
      title: 'Departments'
    }
  },
  {
    path: '/apps/import-export',
    name: 'import-export',
    component: () => import('@/views/import-export/ImportExport'),
    meta: {
      resource: entityImportExport,
      action: 'read',
      title: 'Import/Export'
    }
  },
  {
    path: '/dictionary/notifications',
    name: 'dictionary-notifications',
    component: () => import('@/views/dictionary/notifications/Notifications'),
    meta: {
      resource: entityDictionaryNotificationConditionCategory,
      action: 'read',
      title: 'Sending out notifications'
    }
  },
  {
    path: '/dictionary/insurance-company',
    name: 'dictionary-insurance-company',
    component: () => import('@/views/dictionary/insurance-company/InsuranceCompany'),
    meta: {
      resource: entityInsuranceCompany,
      action: 'read',
      title: 'Insurance companies'
    }
  },
  {
    path: '/dictionary/legal-person',
    name: 'dictionary-legal-person',
    component: () => import('@/views/dictionary/legal-person/LegalPerson'),
    meta: {
      resource: entityLegalPerson,
      action: 'read',
      title: 'Legal persons'
    }
  },
  {
    path: '/dictionary/third-party-organization',
    name: 'dictionary-third-party-organization',
    component: () => import('@/views/dictionary/third-party-organization/ThirdPartyOrganization.vue'),
    meta: {
      resource: entityThirdPartyOrganization,
      action: 'read',
      title: 'Third-party organizations'
    }
  },
  {
    path: '/dictionary/counterparty',
    name: 'dictionary-counterparty',
    component: () => import('@/views/dictionary/counterparty/Counterparty'),
    meta: {
      resource: entityDictionaryCounterparty,
      action: 'read',
      title: 'Counterparties'
    }
  },
  {
    path: '/dictionary/patient-appeal-result',
    name: 'dictionary-patient-appeal-result',
    component: () => import('@/views/dictionary/patient-appeal-result/PatientAppealResult'),
    meta: {
      resource: entityPatientAppealResult,
      action: 'read',
      title: 'Patient appeal results'
    }
  },
  {
    path: '/dictionary/managing/settings/email',
    name: 'dictionary-email-settings',
    component: () => import('@/views/dictionary/managing/settings/EmailSettings.vue'),
    meta: {
      resource: entityEmailSettings,
      action: 'read',
      title: 'Email settings'
    }
  },
  {
    path: '/dictionary/managing/settings/patient-reception-schedule',
    name: 'dictionary-patient-reception-schedule-settings',
    component: () => import('@/views/dictionary/managing/settings/PatientReceptionScheduleSettings.vue'),
    meta: {
      resource: entityPatientReceptionScheduleSettings,
      action: 'read',
      title: 'Patient reception schedule settings'
    }
  },
  {
    path: '/dictionary/managing/settings/cash-register',
    name: 'dictionary-cash-register-settings',
    component: () => import('@/views/dictionary/managing/settings/cash-register-settings/CashRegisterSettings.vue'),
    meta: {
      resource: entityCashRegisterSettings,
      action: 'read',
      title: 'Cash register settings'
    }
  },
  {
    path: '/dictionary/managing/settings/module',
    name: 'dictionary-modules-settings',
    component: () => import('@/views/dictionary/managing/settings/ModuleSettings.vue'),
    meta: {
      resource: entityIntegratorRoot,
      action: 'read',
      title: 'Modules settings'
    }
  },
  {
    path: '/dictionary/managing/settings/price',
    name: 'dictionary-price-settings',
    component: () => import('@/views/dictionary/managing/settings/PriceSettings.vue'),
    meta: {
      resource: entityPriceSettings,
      action: 'read',
      title: 'Price settings'
    }
  },
  {
    path: '/dictionary/managing/settings/treatment-diary',
    name: 'dictionary-treatment-diary-settings',
    component: () => import('@/views/dictionary/managing/settings/TreatmentDiarySettings.vue'),
    meta: {
      resource: entityTreatmentDiarySettings,
      action: 'read',
      title: 'Treatment diary settings'
    }
  },
  {
    path: '/dictionary/managing/settings/dental-service',
    name: 'dictionary-dental-service-settings',
    component: () => import('@/views/dictionary/managing/settings/DentalServiceSettings.vue'),
    meta: {
      resource: entityTreatmentDiarySettings,
      action: 'read',
      title: 'Dental services settings'
    }
  },
  {
    path: '/dictionary/managing/settings/document',
    name: 'dictionary-document-settings',
    component: () => import('@/views/dictionary/managing/settings/document-settings/DocumentSettings.vue'),
    meta: {
      resource: entityDocumentSettings,
      action: 'read',
      title: 'Document settings'
    }
  },
  {
    path: '/dictionary/managing/settings/employee',
    name: 'dictionary-employee-settings',
    component: () => import('@/views/dictionary/managing/settings/EmployeeSettings.vue'),
    meta: {
      resource: entityEmployeeSettings,
      action: 'read',
      title: 'Employee settings'
    }
  },
  {
    path: '/dictionary/managing/physician-protocol-constructor',
    name: 'physician-protocol-constructor',
    component: () => import(
      '@/views/dictionary/managing/physician-protocol-constructor/PhysicianProtocolConstructor.vue'),
    meta: {
      resource: entityDictionaryPatientTemplate,
      action: 'read',
      title: 'Physician protocol constructor'
    }
  },
  {
    path: '/dictionary/managing/physician-protocol/create',
    name: 'physician-protocol-create',
    component: () => import(
      '@/views/dictionary/managing/physician-protocol-constructor/PhysicianProtocolForm.vue'
    ),
    meta: {
      resource: entityDictionaryPatientTemplate,
      action: 'read',
      title: 'Physician protocol creation'
    }
  },
  {
    path: '/dictionary/managing/physician-protocol/edit/:id',
    name: 'physician-protocol-edit',
    component: () => import(
      '@/views/dictionary/managing/physician-protocol-constructor/PhysicianProtocolForm.vue'
      ),
    meta: {
      resource: entityDictionaryPatientTemplate,
      action: 'read',
      title: 'Edit physician protocol',
      isEdit: true
    }
  },
  {
    path: '/dictionary/treatment/treatment-features',
    name: 'dictionary-treatment-features',
    component: () => import(
      '@/views/dictionary/treatment/treatment-features/TreatmentFeature.vue'
      ),
    meta: {
      resource: entityDictionaryTreatmentFeature,
      action: 'read',
      title: 'Treatment features'
    }
  },
  {
    path: '/dictionary/user-activity-log',
    name: 'dictionary-user-activity-log',
    component: () => import('@/views/dictionary/managing/user-activity-log/UserActivityLog'),
    meta: {
      resource: entityUser,
      action: 'read',
      title: 'User activity log'
    }
  },
  {
    path: '/dictionary/pricing-type-value',
    name: 'dictionary-pricing-type-value',
    component: () => import('@/views/dictionary/managing/pricing/Pricing'),
    meta: {
      resource: entityPricingTypeValue,
      action: 'read',
      title: 'Overhead costs'
    }
  },
  {
    path: '/dictionary/patient-appointment-status',
    name: 'dictionary-patient-appointment-status',
    component: () => import('@/views/dictionary/schedule/patient-appointment-status/PatientAppointmentStatus'),
    meta: {
      resource: entityPatientAppointmentStatus,
      action: 'read',
      title: 'Patient appointment statuses'
    }
  },
  {
    path: '/dictionary/treatment/dictionary-medical-services-type',
    name: 'dictionary-medical-services-type',
    component: () => import('@/views/dictionary/treatment/medical-services-type/MedicalServicesType.vue'),
    meta: {
      resource: entityMedicalServiceType,
      action: 'read',
      title: 'Medical services type'
    }
  },
  {
    path: '/dictionary/managing/dictionary-specialist-type',
    name: 'dictionary-specialist-type',
    component: () => import('@/views/dictionary/managing/specialist-type/SpecialistType.vue'),
    meta: {
      resource: entitySpecialistType,
      action: 'read',
      title: 'Types of specialists'
    }
  },
  {
    path: '/dictionary/material-price-list',
    name: 'material-price-list',
    component: () => import('@/views/dictionary/material-price-list/MaterialPriceList.vue'),
    meta: {
      resource: entityMaterialPriceList,
      action: 'read',
      title: 'Material price list'
    }
  },
  {
    path: '/dictionary/leads/utm-tags/utm-sources',
    name: 'dictionary-utm-source',
    component: () => import('@/views/dictionary/leads/utm-tags/UtmSource.vue'),
    meta: {
      resource: entityAdvertisingPlatformUtmSource,
      action: 'read',
      title: 'UTM-sources'
    }
  },
  {
    path: '/dictionary/leads/utm-tags/utm-medium',
    name: 'dictionary-utm-medium',
    component: () => import('@/views/dictionary/leads/utm-tags/UtmMedium.vue'),
    meta: {
      resource: entityAdvertisingPlatformUtmMedium,
      action: 'read',
      title: 'UTM-mediums'
    }
  },
  {
    path: '/dictionary/managing/settings/patient-card',
    name: 'dictionary-patient-card-settings',
    component: () => import('@/views/dictionary/managing/settings/PatientCardSettings.vue'),
    meta: {
      resource: entityPatientCardSettings,
      action: 'read',
      title: 'Patient card settings'
    }
  },
  {
    path: '/dictionary/managing/settings/treatment-presentation',
    name: 'dictionary-treatment-presentation-settings',
    component: () => import('@/views/dictionary/managing/settings/TreatmentPresentationSettings.vue'),
    meta: {
      resource: entityTreatmentPresentationSettings,
      action: 'read',
      title: 'Treatment presentation settings'
    }
  },
  {
    path: '/dictionary/managing/settings/lead',
    name: 'dictionary-lead-settings',
    component: () => import('@/views/dictionary/managing/settings/LeadSettings.vue'),
    meta: {
      resource: entityLeadSettings,
      action: 'read',
      title: 'Lead settings'
    }
  },
  {
    path: '/dictionary/dental-laboratory/work-type',
    name: 'dictionary-work-type',
    component: () => import('@/views/dictionary/dental-laboratory/WorkType.vue'),
    meta: {
      resource: entityWorkType,
      action: 'read',
      title: 'Work types'
    }
  },
  {
    path: '/dictionary/dental-laboratory/material-type',
    name: 'dictionary-material-type',
    component: () => import('@/views/dictionary/dental-laboratory/MaterialType.vue'),
    meta: {
      resource: entityMaterialType,
      action: 'read',
      title: 'Material types'
    }
  },
  {
    path: '/dictionary/dental-laboratory/implant-work-type',
    name: 'dictionary-implant-work-type',
    component: () => import('@/views/dictionary/dental-laboratory/ImplantWorkType.vue'),
    meta: {
      resource: entityImplantWorkType,
      action: 'read',
      title: 'Work type on implant'
    }
  },
  {
    path: '/dictionary/dental-laboratory/implant-system',
    name: 'dictionary-implant-system',
    component: () => import('@/views/dictionary/dental-laboratory/ImplantSystem.vue'),
    meta: {
      resource: entityImplantSystem,
      action: 'read',
      title: 'Implant system'
    }
  },
  {
    path: '/dictionary/dental-laboratory/color-palette',
    name: 'dictionary-color-palette',
    component: () => import('@/views/dictionary/dental-laboratory/ColorPalette.vue'),
    meta: {
      resource: entityColorPalette,
      action: 'read',
      title: 'Color palette'
    }
  },
  {
    path: '/dictionary/recommended-visit-type',
    name: 'dictionary-recommended-visit-type',
    component: () => import('@/views/dictionary/recommended-visit-type/RecommendedVisitType.vue'),
    meta: {
      resource: entityRecommendedVisitType,
      action: 'read',
      title: 'Recommended visit types'
    }
  },
  {
    path: '/dictionary/tooth-state',
    name: 'dictionary-tooth-state',
    component: () => import('@/views/dictionary/tooth-state/ToothState.vue'),
    meta: {
      resource: entityToothState,
      action: 'read',
      title: 'Teeth states'
    }
  },
]
